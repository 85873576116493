body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.g-card {
  margin: 20px;
}

.g-card-image-app {
  border-radius: 10px;
  width: 300px;
  height: 480px;
  box-shadow: 0px 0px 3px 1px #ccc;
}

.g-card-image-website {
  border-radius: 10px;
  width: 360px;
  height: 250px;
  box-shadow: 0px 0px 3px 1px #ccc;
}

.g-card-info {
  margin-top:10px;
  min-height: 100px;
}

.g-card-title {
  font-size: 24px;
  margin: 0px;
}

.g-card-subTitle {
  font-size: 16px;
  margin: 0px;
}

.success-msg {
  color: #57a773;
  padding: 10px 15px;
}

.error-msg {
  color: #ee6352;
  padding: 10px 15px;
}

.form-group.required .control-label:after {
  content: "*";
  color: red;
}

.image-container {
  display: flex;
  flex-direction: column;
}

.display-inline {
  text-align: center;
}

a.disabled {
  pointer-events: none;
  cursor: default;
}